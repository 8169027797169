import { LocalStorage } from '@app/utils';
import { createContext, useContext, useState } from 'react';

// interface IAuthContext {
//   user: string | null;
//   login: (user: string | null) => void;
//   logout: () => void;
// }


const storeNameItems = {
    user: 'RTL_USER'
}

const defaultState = {
    user: '',
    login: (_param: any) => {},
    logout: () => {}
}

export const AuthZeusContext = createContext(defaultState);

export const AuthZeusProvider = ({ children }: any) => {

    //const { getItem, setItem } = useLocalStorage()

    const localStorage = new LocalStorage()

    const [user, setUser] = useState(() => {
       
        const user = localStorage.getItem(storeNameItems.user)
        if (user) {
            if(typeof user === 'string') {
                return user
            }else {
                return JSON.parse(user);
            }
        }
        return null
    })

    const login = (user: string) => {
        setUser(user)
        localStorage.setItem(storeNameItems.user, user)
    }

    const logout = () => {
        setUser(null)
    }

    const store = {
        user,
        login,
        logout
    }

    return (
        <AuthZeusContext.Provider value={store}>
            {children}
        </AuthZeusContext.Provider>
    )
}

export const useZeusAuth = () => {
    return useContext(AuthZeusContext)
}
