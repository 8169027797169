import React, { FC } from 'react'

// Components
import AppTagButton from '@app/components/AppTagButton'
import ButtonsGroup from './../ButtonsGroup'
// Style
import style from './style.module.scss'
interface IVacancyButton {
	titleVanacy: string,
	path?: string,
	callback?: () => void
}

const vacancyButton: FC<IVacancyButton> = (props) => {

	const {
		titleVanacy,
		// path,
		callback,
	} = props

	return (
		<div className={style.vacancy_button} onClick={callback}>
			<div className={style.vacancy_button__left_section}>
				<div className={style.vacancy_title}>
					{titleVanacy}
				</div>
				<div className={style.vacancy_tags}>
					<AppTagButton text='ID PUESTO: 2345' secondary />
					<AppTagButton text='FUNCIÓN SAP: 122346' secondary />
				</div>
			</div>
			<div className={style.vacancy_button__right_section}>
				<div className={style.vacancy_status}>
					<AppTagButton text='En curso' />
				</div>
				<ButtonsGroup />
			</div>
		</div>
	);
};

export default vacancyButton;