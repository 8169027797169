
import CryptoJS from 'crypto-js';

export class CryptoAesService {

  // The secret token value to use for encrypt
  private readonly secretToken: string;
  // The key value encrypted
  private readonly key: any;

  constructor() {
    this.secretToken = 'secretToken2021$$';
    this.key = this.secretToken;
  }

  /**
   * Gets the encrypt value of a plain text
   * @param plainText the text to encrypt
   */
  public encryptUsingAES256(plainText: string): string {
    const encrypted = CryptoJS.AES.encrypt(
      plainText,
      this.key
    );
    return encrypted.toString();
  }

  /**
   * Gets the text decrypted value
   * @param encryptedText the text decrypted
   */
  public decryptUsingAES256(encryptedText: string): string {
    const decrypt = CryptoJS.AES.decrypt(
      encryptedText,
      this.key
    );
    return decrypt.toString(CryptoJS.enc.Utf8);
  }
}
