import { FC } from 'react';
import { IAppButtonProps } from './interface';
// Style
import style from './style.module.scss';

const index: FC<IAppButtonProps> = (props) => {
	const {
		text,
		secondary = false,
		icon,
		customStyles,
		callback,
		disabled = false
	} = props

	const attrs = {
		style: customStyles,
		onClick: callback,
		className: `${style.app_button} ${secondary ? style.app_button__secondary : ''}`,
		disabled: disabled
	}

	return (
		<button
			type='button'
			{...attrs}>
			{text}
			{
				icon &&
				<img src={icon} alt="Icono" />
			}
		</button>
	);
};

export default index;