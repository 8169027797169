import React from 'react';
// Style
import style from './style.module.scss'
// Assets
import rocketImage from '@app/assets/rocket.png'
const index = () => {
	return (
		<div className={style.app_no_results}>
			<img src={rocketImage} alt="Cohete reclutalia" />
			<div className={style.app_no_results__text}>
				<h2 className={style.app_no_results_title}>
					¡Lo sentimos!
				</h2>
				<p className={style.app_no_results_text}>
					Aún no tenemos información para mostrarte. Puedes iniciar<br />
					configurando algún puesto
				</p>
			</div>
		</div>
	);
};

export default index;