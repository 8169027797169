import { FC } from 'react';


// Style
import styles from './style.module.scss';
// Assets
import {IJobOfferPreview } from './interface';

const JobOfferPreview: FC<IJobOfferPreview> = (props) => {

    const { 
        featured,
        brand,
        brandAlt,
        // date,
        id,
        name,
        companyTheme = '#ddd',
        salary,
        address,
        description
     } = props

     const customStyles = {
        color: companyTheme,
        borderColor: '#E71618'
     }

     const customBrandStyles = {
        enabled: { 
            backgroundColor: '#fff',
            borderColor: '#EDEDED',
            height: 89,
            width: 93,
        },
        disabled: { 
            backgroundColor: '#808080' 
        }
     }

    return (
         <div className={styles.main_offer}>
            <header className={styles.main_offer__header}>
                <div className={styles.main_offer__featured__container}>
                    <div 
                        className={styles.main_offer__featured__image} 
                        style={{ borderColor: customStyles.borderColor }}
                    >
                        {featured &&
                            <img src={featured} alt="Featured" />
                        }
                    </div>
                    <div
                        className={styles.main_offer__featured__brand}
                        style={brand ? customBrandStyles.enabled : customBrandStyles.disabled}
                    >
                        {brand && 
                            <img src={brand} alt={brandAlt} />
                        }
                    </div>
                </div>
            </header>
            <div>
                <div className={styles.main_offer__content}>
                    <div className={styles.main_offer__content__inner}>
                        <p className={styles.main_offer__content__id}>
                            <small>{id}</small>
                        </p>
                        <h3
                            style={{color: customStyles.color}}
                            className={styles.main_offer__content__title}>
                            {name}
                        </h3>
                        <div className={styles.main_offer__salary}>
                            <p className={styles.main_offer__content__salary}>
                                {salary}
                            </p>
                        </div>
                        <p className={styles.main_offer__content_address}>
                            {address}
                        </p>
                    </div>
                </div>

                <div className={styles.main_offer__description}>

                    <div className={styles.main_offer__vacancy__card}>
                        <h4>
                            Objetivo del puesto
                        </h4>
                        <ul>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>{description}</span>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.main_offer__vacancy__card}>
                        <h4>
                            Funciones principales
                        </h4>
                        <ul>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>
                                    Atender las operaciones de clientes y usuarios en ventanilla y caja móvil de forma rápida, amable y sin errores.
                                 </span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>
                                    Realizar todas las operaciones con honestidad y con apego a las normas bancarias y valores de la empresa.
                                 </span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>
                                    Cumplir la normatividad de Banco de México, con las políticas y procedimientos de caja de BAZ y la metodología 7’s.
                                 </span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>
                                    Resguardar el efectivo en la zona segura y acatar las medidas de seguridad del búnker.
                                 </span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>
                                    Alcanzar las metas de productividad de caja y ejecutar la implementación del plan anti fila.
                                 </span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>
                                    Brindar un servicio amable y gentil para que los clientes regresen y nos recomienden.
                                 </span>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.main_offer__vacancy__card}>
                        <h4>Perfil ideal del candidato</h4>
                        <ul>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Preparatoria Concluida.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Experiencia de un año como cajero o manejando efectivo.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Gusto por el trato con clientes.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Disponibilidad de rolar turnos.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Disponibilidad de trabajar fines de semana.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Honestidad en el desempeño de sus funciones.</span>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.main_offer__vacancy__card}>
                        <h4>Oferta de valor</h4>
                        <ul>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Sueldo fijo en promedio: $1,640 semanal.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Comisiones semanales en promedio:$600 pesos.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Prestaciones de ley: Vacaciones iniciando con 6 días al año, Prima vacacional del 25% anual, Aguinaldo de 15 días al año, el cuál aumenta de acuerdo a tu antigüedad.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Condiciones Laborales: Horario de 8 horas diarias de Lunes a Domingo con un día de descanso entre semana. Horario rotativo semanalmente. Un día de descanso al mes en fin de semana. Seguro de vida que incrementa conforme a percepciones y antigüedad.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Beneficios del puesto: Plan de Entrenamiento acorde al puesto, asi como para el desarrollo de habilidades personales. Inscripción a programas de gobierno para regularizar tus estudios de Bachillerato, Convenios con Universidades para continuar con tus estudios.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Beneficios financieros: Adelanto de nómina. Acceso a tarjeta de crédito. Descuentos desde el 10% en productos dentro de tiendas Elektra y Salinas & Rocha, en compras de contado desde el primer día, y a crédito a partir de los 9 meses de antigüedad. Crédito Personal con tasa preferencial a partir de los 2 años. Cambio de divisas. Cuentas de inversión. Caja de ahorro.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Servicios: Acceso al Nutriólogo, Psicólogo, Odontólogo, Biblioteca, concursos y programas para mejorar tu salud, Consejos y acompañamiento en tus logros, nuevos integrantes en la familia y celebraciones importantes.</span>
                            </li>
                            <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>Promociones y Convenios para ti y tu familia: Descuentos en gimnasios, cines, centros de entretenimiento, conciertos, restaurantes, tiendas departamentales, ópticas, laboratorios, clínicas, colegios, escuelas de idiomas, universidades, aerolíneas, viajes, agencias automotrices, guarderías.</span>
                            </li>
                             <li
                                style={{
                                    color: customStyles.color,
                                    borderColor: customStyles.borderColor
                                }}
                            >
                                 <span style={{color: "#414140"}}>¡Atrévete a ser parte de nuestro equipo!</span>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
         </div>
                
    )
};

export default JobOfferPreview;