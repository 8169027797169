import { FC } from 'react';
import { IAppTooltipProps } from './interface';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import style from './style.module.scss';
import helpIcon from '@app/assets/icon-help-grey.png';

const AppTooltip:FC<IAppTooltipProps> = (props) => {
    
	const {
        children,
        arrow,
        placement,
		action
	} = props

    const iconSize = {
        width: 18,
        height: 18
    }

	const handleClick = () => {
		if(action) action()
	}

	return (
        <Tippy
            arrow={arrow}
            placement={placement ? placement : 'right'}
            content={
                <div className={style.app_tooltip__content}>
                    {children}
                </div>
            }
        >
            <div
                aria-label='Ayuda'
                className={style.app_tooltip}
                onClick={handleClick}
            >
                <div className={style.app_tooltip__icon}>
                    <img
                        src={helpIcon}
                        alt='Help'
                        width={iconSize.width}
                        height={iconSize.height} 
                    />
                </div>
            </div>
        </Tippy>
	);
};

export default AppTooltip;