import React from 'react';
import HomeView from './Home/HomeView'
import ToBeConfigureView from './ToBeConfigure/ToBeConfigureView';
import VacancyConfiguration from './VacancyConfiguration'
import VacancyDetailsVew from './VacancyDetailsVew/VacancyDetailsVew';

export const HomeRoutes = [
  {
    path: '/',
    element: <HomeView />
  },
  {
    path: '/vancys-to-be-configure',
    element: <ToBeConfigureView />
  },
  {
    path: '/vancys-configuration',
    element: <VacancyConfiguration />
  },{
    path: '/vacancy-details',
    element: <VacancyDetailsVew />
  }
];