import React from 'react';
import style from './style.module.scss'
const index = () => {
	return (
		<div className={style.app_lateral_menu}>

		</div>
	);
};

export default index;