import axios, {  AxiosRequestConfig } from 'axios';
import { environment } from '../../../environments/base';

export interface ResponseAPI {
    userId: number;
    id: number;
    title: string;
    body: string;
}

export const logintZeusSGService = async (body: any) => {

    const config: AxiosRequestConfig = {
        data: body,
    }

    const apiZeusGS = `${environment.REACT_APP_ZEUS_GS}`

    try {

        const response = await (await axios.post(apiZeusGS, config)).data
        //console.log("🚀 ~ file: auth-user.service.ts:34 ~ logintZeusSGService ~ response", response)
        
        if(response) return response
        
    }catch(e) {
        console.error('Error in loginRequestZeusSG', e)
        //throw new Error(handleCommonErrors(e as AxiosError));
    }
}

// function handleCommonErrors (args: AxiosError<unknown, any>): string | undefined {
//     throw new Error('Function not implemented.');
// }
