import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthZeusContext } from '@app/context/AuthZeusConext/AuthZeusContext';

// Components
import AppHeader from '@app/components/AppHeader/AppHeader';
import AppLateralMenu from '@app/components/AppLateralMenu';
// Style
import style from './style.module.scss'

const HomeLayout = () => {

	const { user } = useContext(AuthZeusContext)
	
	return (
		<div className={style.home_layout}>
			<div className={style.home_layout__header}>
				<AppHeader userName={user} />
			</div>
			<div className={style.home_layout__body}>
				<div className={style.home_layout_lateral_menu}>
					<AppLateralMenu />
				</div>
				<div className={style.home_layout_content}>
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default HomeLayout;