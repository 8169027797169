import React, { useEffect, useState } from 'react'

// Style
import style from './style.module.scss'
// Assets
import AppPageTitle from '@app/components/AppPageTitle'
import VacancyResumeCard from './Components/VacancyResumeCard/VacancyResumeCard'
import AppAlert from '@app/components/AppAlert'
import AppUploadImage from '@app/components/AppUploadImage/AppUploadImage'
import { fileToBase64 } from '@app/utils/file-to-base64';
import AppTooltip from '@app/components/AppTooltip'
import JobOfferPreview from './Components/JobOfferPreview/JobOfferPreview'
import AppButton from '@app/components/AppButton'
import AppModal from '@app/components/AppModal'
import IconReloadImage from '@app/assets/icon-outline-reload-image-dark.png'
import IconTrash from '@app/assets/icon-outline-trash-dark.png'
import IconArrowNext from '@app/assets/icon-arrow-next-rounded-white.png'
import { useNavigate } from 'react-router'


const VacancyDetailsVew = () => {

	const [featured, setFeatured] = useState('');
	const [logo, setLogo] = useState('');
	const [showDialog, setShowDialog] = useState(false);
	const [triggerRemove, setTriggerRemove] = useState(false);
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const [triggerRemoveLogo, setTriggerRemoveLogo] = useState(false);
	const [triggerUpdateLogo, setTriggerUpdateLogo] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [showRemoveDialog, setShowRemoveDialog] = useState(false);
	const [showRemoveDialogLogo, setShowRemoveDialogLogo] = useState(false);
	const [successUploaded, setSuccessUploaded] = useState(false);
	const [waringUploaded, setWarningUploaded] = useState(false);

	const navigate = useNavigate()

	const contentResume = {
		title: 'Cajero sucursal bancaria',
		tag: 'En curso',
		metas: ['ID PUESTO 2345', 'FUNCIÓN SAP: 122346']
	}

	const contentView = {
		title: 'Configuración de puesto',
		subtitle: '1. PUBLICACIÓN'
	}

	const warningContent = {
		title: 'Los cambios no se guardarán',
		text: '¿Seguro que deseas salir sin guardar cambios?'
	}

	const removeWarningContent = {
		title: 'La imagen actual no se guardará',
        text: '¿Seguro que deseas eliminar el archivo? No podrás recuperarlo después',
		accept: 'Sí, eliminar',
		cancel: 'Cancelar'
	}

	const handleFeatured = async (file: any) => {

		if(!file) {

			setFeatured('')
			setTriggerRemove(false)
		} else {
			
			setTriggerUpdate(false)
			
			const resultBase64 = await fileToBase64(file)
			setFeatured(`${resultBase64}`)
		}
		
	}

	const handleLogo = async (file: any) => {

		if(!file) {

			setLogo('')
			setTriggerRemoveLogo(false)
		} else {

			setTriggerUpdateLogo(false)
			const resultBase64 = await fileToBase64(file)
			setLogo(`${resultBase64}`)
		}
	}

	const handlerError = (param: boolean) => {

		if(param) {
			setWarningUploaded(true)
			setSuccessUploaded(false)
			setTimeout(() => {
				setWarningUploaded(false)
			}, 2500	)
		}else {

			setWarningUploaded(false)
			setSuccessUploaded(true)
			setTimeout(() => {
                setSuccessUploaded(false)
			}, 2500)
		}
	}

	const jobOfferContent = {
		featured: featured,
		brand: logo,
		brandAlt: '',
		date: '',
		id: '',
		name: 'Cajero sucursal bancaria',
		companyTheme: '#E71618',
		salary: '$6,000 - $12,000',
		address: 'Av Belisario Dominguez 0, Centro Azcapotzalco',
		description: 'Garantizar que el cliente reciba una excelente atención y servicio, realizando transacciones rápido, precisas  y eficientes.',	
	}

	const customTooltipStyles = {
		display: 'inline-block',
		verticalAlign:'middle',
		marginLeft: 10,
		marginRight: 10
	}

	const prevControlStyles = {
		backgroundColor: '#FFFFFF',
		border: '2px solid #F5F6F7',
		boxShadow:' 0px 2px 5px rgba(38, 51, 77, 0.03)',
		borderRadius: 30,
		color: '#B2B5C9',
		marginRight: 10
	}

	const handlerRemove = (param: boolean) => {
		setTriggerRemove(param)
	}

	const handlerUpdate = (param: boolean) => {
		setTriggerUpdate(param)
	}

	const handlerRemoveLogo = (param: boolean) => {
		setTriggerRemoveLogo(param)
	}

	const handlerUpdateLogo = (param: boolean) => {
		setTriggerUpdateLogo(param)
	}

	const featuredRules = { 
		maxSize: 1000,
		type: ['image/jpeg', 'image/jpg', 'image/png'],
		dimensions: [1920,370]
	}

	const logoRules = { 
		maxSize: 1000,
		type: ['image/jpeg', 'image/jpg', 'image/png'],
		dimensions: [800,800]
	}

	useEffect(() => {

		if(featured && logo) {

			setDisabled(false)
		}else {

			setDisabled(true)
		}

	},[ featured, logo ])

	return (
		<div className={style.vacancy_details}>
			<div className={style.vacancy_details__messages}>
				<div className={style.vacancy_details__messages_inner}>
					<AppAlert severity='danger' show={waringUploaded}>
						Algo salió mal, el archivo es incorrecto
					</AppAlert>
					<AppAlert severity='success' show={successUploaded}>
						El archivo  se subió correctamente
					</AppAlert>
				</div>
			</div>
			<div className={style.vacancy_details__section}>
				<div className={style.vacancy_details__resume}>
					<div className={style.vacancy_details__title}>
						<AppPageTitle
							title={contentView.title}
						/>
					</div>
					<VacancyResumeCard 
						title={contentResume.title}
						tag={contentResume.tag}
						footList={contentResume.metas}
					/>
					<div className={style.vacancy_details__steps}>
						<div className={style.vacancy_details__steps_description}>
							<h3>{contentView.subtitle}</h3>
							<p>
								<strong>¡Así se verá la publicación en Reclutalia! 👇</strong>
							</p>
							<p>
								Asegúrate que la vista de previsualización se vea justo como quieres que se vea en Reclutalia
							</p>
						</div>
						<div className={style.vacancy_details__steps_controls}>
							<h4>
								Imagen de portada
								<span style={customTooltipStyles}>
									<AppTooltip>
										<ul>
											<li>Formato: JPEG, PNG o JPG </li>
											<li>Medidas: 1920 x 370px </li>
											<li>Máx: 1 MB </li>
										</ul>
									</AppTooltip>
								</span>
							</h4>
							<AppUploadImage
								handleFile={handleFeatured} 
								triggerRemove={triggerRemove}
								triggerUpdate={triggerUpdate}
								handlerError={handlerError}
								rules={featuredRules}
							/>
						</div>
						<div className={style.vacancy_details__steps_controls}>
							<h4>
								Logotipo de tu empresa
								<span style={customTooltipStyles}>
									<AppTooltip>
										<ul>
											<li>Formato: JPEG, PNG o JPG </li>
											<li>Medidas: 800 x 800 px </li>
											<li>Máx: 1 MB </li>
										</ul>
									</AppTooltip>
								</span>
							</h4>
							<AppUploadImage 
								handleFile={handleLogo}
								triggerRemove={triggerRemoveLogo}
								triggerUpdate={triggerUpdateLogo}
								handlerError={handlerError}
								rules={logoRules}
							/>
						</div>
					</div>
				</div>
				<div className={style.vacancy_details__preview}>

					{featured && 
						<div className={style.main_offer__preview_controls_area}>
							<div className={style.main_offer__preview_group_controls}>
								<button
									onClick={() => { handlerUpdate(true) }}
									type='button'
									className={style.main_offer__preview_control}
									aria-label='Recargar imagen'>
									<img
										width={21}							
										src={IconReloadImage}
										alt="Recargar imagen" />
								</button>
								<button
									onClick={() => { setShowRemoveDialog(true) }}
									type='button'
									className={style.main_offer__preview_control}
									aria-label='Remover imagen'>
									<img
										width={18}
										src={IconTrash}
										alt="Remover imagen" />
								</button>
							</div>
						</div>
					}

					{logo &&
						<div className={style.main_offer__preview_logo_controls_area}>
							<div className={style.main_offer__preview_logo_group_controls}>
								<button
									onClick={() => { handlerUpdateLogo(true) }}
									type='button'
									className={style.main_offer__preview_control}
									aria-label='Recargar imagen'>
									<img
										width={17}							
										src={IconReloadImage}
										alt="Recargar imagen" />
								</button>
								<button
									onClick={() => { setShowRemoveDialogLogo(true) }}
									type='button'
									className={style.main_offer__preview_control}
									aria-label='Remover imagen'>
									<img
										width={14}
										src={IconTrash}
										alt="Remover imagen" />
								</button>
							</div>
						</div>
					}
					
					<JobOfferPreview {...jobOfferContent} />

					<div className={style.vacancy_details__controls}>
						<AppButton
							text='Volver'
							customStyles={prevControlStyles}
							callback={ () => {setShowDialog(true)} } />
						<AppButton 
							disabled={disabled}
							text='Siguiente' 
							icon={IconArrowNext} />
					</div>
				</div>
			</div>
			
			<AppModal
                title={warningContent.title}
                text={warningContent.text}
                onClose={() => setShowDialog(false)}
				onAccept={() => {
					navigate('/vancys-to-be-configure')
				}}
                show={showDialog}
				showAccept={true}
				acceptName='Sí, salir'
				cancelName='Cancelar'
            />

			{/* Modal Featured */}
			<AppModal
                title={removeWarningContent.title}
                text={removeWarningContent.text}
                onClose={() => setShowRemoveDialog(false)}
				onAccept={() => { 
					handlerRemove(true)
					setShowRemoveDialog(false)
				}}
                show={showRemoveDialog}
				showAccept={true}
				acceptName={removeWarningContent.accept}
				cancelName={removeWarningContent.cancel}
            />

			{/* Modal Logo */}
			<AppModal
                title={removeWarningContent.title}
                text={removeWarningContent.text}
                onClose={() => setShowRemoveDialogLogo(false)}
				onAccept={() => { 
					handlerRemoveLogo(true)
					setShowRemoveDialogLogo(false)
				}}
                show={showRemoveDialogLogo}
				showAccept={true}
				acceptName={removeWarningContent.accept}
				cancelName={removeWarningContent.cancel}
            />
			
		</div>
	);
};

export default VacancyDetailsVew;