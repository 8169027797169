// Style
import './style.scss'

const AppSpinner = () => {

    const svgAttrs = {
        className: 'app_spinner__material_svg',
        width: '65px',
        height: '65px',
        viewBox: '0 0 66 66'
    }

    const circleAttrs = {
        className: 'app_spinner__material_svg_path',
        fill: 'none',
        strokeWidth: '6',
        cx: '33',
        cy: '33',
        r: '30'
    }

	return (
		<div className='app_spinner'>
            <div className='app_spinner__figure'>
                <svg {...svgAttrs}>
                    <circle strokeLinecap="round" {...circleAttrs}></circle>
                </svg>
            </div>
        </div>
	);
};

export default AppSpinner;