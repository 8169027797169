import React from 'react'
import './style.scss'
// Images
import trashIcon from './assets/trash-icon.png'
import duplicateIcon from './assets/duplicate-icon.png'
import dotsIcon from './assets/dots-icon.png'

const index = () => {
  return (
    <div className='buttons_group'>
      <div className='buttons_group__btn'>
				<img src={dotsIcon} alt="" />
			</div>
			<div className='buttons_group__btn'>
				<img src={duplicateIcon} alt="" />
			</div>
			<div className='buttons_group__btn'>
				<img src={trashIcon} alt="" />
			</div>
    </div>
  );
};

export default index;