// import axiosCancelToken from '@hooks/axiosCancelToken';
import axios from 'axios';
import { environment } from '../../environments/base';

// export const source = axiosCancelToken();

export const API_RECLUTALIA = axios.create({
    baseURL: environment.REACT_APP_BASE_API_GATEWAY,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: false,
    timeout: 60000
});

export const RECLUTALIA = {
    AUTH: {
        OWNER_AUTH: '/auth',
        GET_EMPLOYEE_NUMBER: '/employee-number',
        CHECK_SESSION: '/check-session',
        LOGIN: '/auth/login',
        GET_USER: '/auth/get_user',
        ABOUT: '/users/about',
        USERS: '/users',
        GET_OWNER
    }
}

function GET_OWNER (provider: string, employeeId: string) {
  return `/owners?provider=${provider}&identifier=${employeeId}`
}
