import React from 'react';
// Components
import AppPageTitle from '@app/components/AppPageTitle'
import VacancyList from './Components/VacancyList'
// Style
import style from './style.module.scss'
import AppAlert from '@app/components/AppAlert';


const VacanciesToBeConfigured = () => {

	const HtmlTooltip = () => (
		<ol>
			<li>
				Descarga la plantilla
			</li>
			<li>
				Completa el formato según el puesto que desees configurar.
			</li>
			<li>
				Una vez llenado, guárdalo y vuelve a subirlo, ya sea arrastrándolo desde tus archivos o dando clic en "cárgalo".
			</li>
			<li>
				Si hay algún error en tu información, te lo haremos saber.
			</li>
			<li>
				Selecciona "Cargar puesto" para continuar.
			</li>
		</ol>
	)

	const pageContents = {
		title: 'Puestos por configurar'
	}

	return (
		<div className={style.to_be_configure}>
			<div className={style.to_be_configure__title}>
				<AppPageTitle
					title={pageContents.title}
					tooltip={<HtmlTooltip />}
				/>
			</div>

			{/* Temporal container */}
			<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
				<AppAlert severity='success' show={true}>
					Se guardo tu micrositio correctamente
				</AppAlert>
				<AppAlert severity='info' show={true}>
					Estamos corroborando la información, espera
				</AppAlert>
				<AppAlert severity='danger' show={true}>
					Algo salió mal, el archivo es incorrecto
				</AppAlert>
				<AppAlert severity='success' show={true}>
					Example text with <a href="https://localhost">anchor tag</a>
				</AppAlert>
				<AppAlert severity='info' title='Éxito' show={true}>
					Example alert with title
				</AppAlert>
			</div>

			<div className={style.to_be_configure__vacancy_list}>
				<VacancyList />
			</div>

		</div>
	);
};

export default VacanciesToBeConfigured;