export const fileToBase64 = async (file: any): Promise<any> => {
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })

    return await toBase64(file)
}

export const getBase64StringFromDataURL = (base64Data: string) => {
    return base64Data.replace('data:', '').replace(/^.+,/, '')
}

export const getBase64Extension = (base64Data: string) => {
    return base64Data.split(';')[0].split('/')[1]
}