import React, { FC } from "react";
import { IVacancyResumeCard } from "./interface";
import style from "./style.module.scss";
import AppTagButton from "@app/components/AppTagButton";

const VacancyResumeCard: FC<IVacancyResumeCard> = (props: any) => {

    const {
        title = 'Título de vacante',
        tag = 'Estado',
        description,
        footList
    } = props

    return (
        <div className={style.resume_card}>
            <div className={style.resume_card__heading}>
                <h3>{title}</h3>
                <AppTagButton text={tag} />
            </div>
            {description && (
                <div className={style.resume_card__body}>
                    {description}
                </div>)
            }
            <div className={style.resume_card__foot}>
                {footList && footList.map((item: string, index: number) => (
                    <span className={style.resume_card__foot_item} key={index}>
                        {item}
                    </span>))
                }
            </div>
        </div>
    )
}

export default VacancyResumeCard;