import React from 'react';
// Components
import AppPageTitle from '@app/components/AppPageTitle'
import UploadVacancy from './Components/UploadVacancy'
// Style
import style from './style.module.scss'
const index = () => {
	return (
		<div className={style.vacancy_configuration}>
			<div className={style.vacancy_configuration__title}>
				<AppPageTitle
					title="Configuración de puesto"
					secondary
				/>
			</div>
			<div className={style.vacancy_configuration__new_vacancy}>
				<UploadVacancy />
			</div>
			<div className={style.vacancy_configuration__table}>

			</div>
		</div>
	);
};

export default index;