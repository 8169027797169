import React, { FC, useEffect, useRef, useState } from 'react'
import { IAppUploadImage } from './interface'
// Style
import style from './style.module.scss'
import { fileToBase64 } from '@app/utils/file-to-base64';

const AppUploadImage: FC<IAppUploadImage> = (props) => {

	const { 
		handleFile,
		triggerRemove,
		triggerUpdate,
		rules,
		handlerError,
	} = props

	const { maxSize, type, dimensions } = rules

	const [dragActive, setDragActive] = useState(false)
	const [uploaded, setUploaded] = useState(false)
	const [fileName, setFileName] = useState('')

	const inputRef = useRef<HTMLInputElement>(null)
	const controlRef = useRef<HTMLButtonElement>(null);

	const processImage = (source: string) => {
		return new Promise((resolve, reject) => {
			const img: HTMLImageElement = new Image()
			img.onload = () => resolve([img.width, img.height])
			img.onerror = reject
    		img.src = source
		})
	}

	const getImageDimensions = async (source: HTMLInputElement) => {
		const resultBase64 = await fileToBase64(source)
		return await processImage(resultBase64)
	}

	const validateImage = async (file: HTMLInputElement) => {
		 
		const size = file.size
		const format = file.type
		const validSize = size >= maxSize
		const validType = type.join(',').includes(format)
		const imgDimensions = await getImageDimensions(file)
		const validDimensions = `${dimensions}` === `${imgDimensions}`
		
		const resolveValidation = (validSize && validType && validDimensions)
		return resolveValidation
	}

	const handleDrag = (e: any) => {

		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	}
	
	const handleDrop = async (e: any) => {

		e.preventDefault();
		e.stopPropagation();

		try {
			setDragActive(false);

			if (e.dataTransfer.files && e.dataTransfer.files[0]) {

				const selectedFile = e.dataTransfer.files[0]
				
				const isValidFile = await validateImage(e.dataTransfer.files[0])

				if(isValidFile) {
					handleFile(selectedFile)
					setFileName(`${selectedFile.name}`)
					setUploaded(true)
					handlerError(false)
								
				}else {

					handlerError(true)
				}
				
			}

		}catch {
			handlerError(true)
		}

	}
	
	const handleChange = async (e: any) => {

		e.preventDefault();
		e.stopPropagation();

		try {

			if (e.target.files && e.target.files[0]) {

				const isValidFile = await validateImage(e.target.files[0])
				
				if(isValidFile) {

					handleFile(e.target.files[0]);
					setUploaded(true)
					setFileName(`${e.target.files[0].name}`)
					handlerError(false)
				}else {

					handlerError(true)
				}
			}
			
		} catch {

			handlerError(true)
		}
	}

	const triggerUploadFile = () => {
		
		inputRef.current!.click();
	}

	const handlerClose = () => {

		inputRef.current!.value = ''
		handleFile(null)
		setUploaded(false)
		setFileName('')
	}

	const triggerClose = () => {

		controlRef.current!.click()
	}

	useEffect(() => {

		if (triggerRemove) return triggerClose()
		if (triggerUpdate) return triggerUploadFile()

	}, [triggerRemove, triggerUpdate]);

	const containerStyles = (): string => {

		const defaultStyle = style.app_upload_image
		const isUploaded = style.is_uploaded
		const isDrag = style.is_drag_active

		if(uploaded) return `${defaultStyle} ${isUploaded}`
		if(dragActive) return `${defaultStyle} ${isDrag}`
		
		return `${defaultStyle}`
	}

	return (
		<div
			className={containerStyles()}
			onDragEnter={handleDrag} 
			onDragLeave={handleDrag} 
			onDragOver={handleDrag} 
			onDrop={handleDrop}>
			<div 
				className={style.app_upload_image__area}
			>
				<input
					className={style.app_upload_image__input}
					ref={inputRef}
					accept="image/jpeg, image/jpg, image/png"
					//value={selectedFile}
					type="file"
					onChange={handleChange} />
				<label>
					<div className={style.app_upload_image__state}>
						<span className={style.app_upload_image__upload_icon}>
							<svg width={22} height={18} viewBox="0 0 22 18" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M0.5 3C0.5 2.40326 0.737053 1.83097 1.15901 1.40901C1.58097 0.987053 2.15326 0.75 2.75 0.75H19.25C19.8467 0.75 20.419 0.987053 20.841 1.40901C21.2629 1.83097 21.5 2.40326 21.5 3V15C21.5 15.5967 21.2629 16.169 20.841 16.591C20.419 17.0129 19.8467 17.25 19.25 17.25H2.75C2.15326 17.25 1.58097 17.0129 1.15901 16.591C0.737053 16.169 0.5 15.5967 0.5 15V3ZM2 13.06V15C2 15.414 2.336 15.75 2.75 15.75H19.25C19.4489 15.75 19.6397 15.671 19.7803 15.5303C19.921 15.3897 20 15.1989 20 15V13.06L17.31 10.371C17.0287 10.0901 16.6475 9.93232 16.25 9.93232C15.8525 9.93232 15.4713 10.0901 15.19 10.371L14.31 11.25L15.28 12.22C15.3537 12.2887 15.4128 12.3715 15.4538 12.4635C15.4948 12.5555 15.5168 12.6548 15.5186 12.7555C15.5204 12.8562 15.5018 12.9562 15.4641 13.0496C15.4264 13.143 15.3703 13.2278 15.299 13.299C15.2278 13.3703 15.143 13.4264 15.0496 13.4641C14.9562 13.5018 14.8562 13.5204 14.7555 13.5186C14.6548 13.5168 14.5555 13.4948 14.4635 13.4538C14.3715 13.4128 14.2887 13.3537 14.22 13.28L9.06 8.121C8.77875 7.8401 8.3975 7.68232 8 7.68232C7.6025 7.68232 7.22125 7.8401 6.94 8.121L2 13.061V13.06ZM12.125 5.25C12.125 4.95163 12.2435 4.66548 12.4545 4.4545C12.6655 4.24353 12.9516 4.125 13.25 4.125C13.5484 4.125 13.8345 4.24353 14.0455 4.4545C14.2565 4.66548 14.375 4.95163 14.375 5.25C14.375 5.54837 14.2565 5.83452 14.0455 6.0455C13.8345 6.25647 13.5484 6.375 13.25 6.375C12.9516 6.375 12.6655 6.25647 12.4545 6.0455C12.2435 5.83452 12.125 5.54837 12.125 5.25Z" fill={uploaded ? '#10B509' : '#676766' }/></svg>
						</span>
						{uploaded ? 
							<p>
								{fileName}
							</p> : 
							<p>
								Arrastra tu archivo o <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={triggerUploadFile}>cárgalo</span>
							</p>
						}
					</div>
				</label>
				{uploaded &&
					<button
						onClick={handlerClose}
						ref={controlRef}
						type='button'
						className={style.app_upload_image__close}>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M1.01074 14.9911L8.00141 8.00043M14.9921 1.00977L8.00008 8.00043M8.00008 8.00043L1.01074 1.00977M8.00141 8.00043L14.9921 14.9911" stroke="#10B509" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
					</button>
				}
			</div>
		</div>
	);
};

export default AppUploadImage;