// import React from "react";
import axios, {  AxiosRequestConfig } from 'axios';
import { environment } from '../../../environments/base';

interface IEncryptData {
    employee_number: string,
    external_user: boolean,
    password: string,
}

export const encryptService = async (data: IEncryptData) => {

    const apiEncrypt = `${environment.REACT_APP_ENCRYPT_LOGIN}`

    try {

        const dataRequest = data

        const config: AxiosRequestConfig = {
            data: dataRequest
        }

        const response = await (await axios.post(apiEncrypt, config)).data
        //console.log("🚀 ~ file: auth-user.service.ts:34 ~ logintZeusSGService ~ response", response)
        
        if (response?.statusCode >= 200 && response?.statusCode < 300) {
            return response
        } else {
            throw new Error(response)
        } 
        
    }catch(e) {
        console.error('Error in encryptService', e)
        //throw new Error(handleCommonErrors(e as AxiosError));
    }

 
}

export const decryptService = async (data: any) => {

    const apiDecrypt = `${environment.REACT_APP_DECRYPT_LOGIN}`
    
    try {

        const dataRequest = data

        const config: AxiosRequestConfig = {
            data: dataRequest
        }

        const response = await (await axios.post(apiDecrypt, config)).data
        //console.log("🚀 ~ file: crypt.service.ts:56 ~ decryptService ~ response", response)
        
        if (response?.statusCode >= 200 && response?.statusCode < 300) {
            return response
        } else {
            throw new Error(response)
        } 
        
    }catch(e) {
        console.error('Error in decryptService', e)
        //throw new Error(handleCommonErrors(e as AxiosError));
    }

 
}