import { FC, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import AppButton from "../AppButton";
import { IAppModalProps } from "./interface";

const AppModal: FC<IAppModalProps> = (props: any) => {

    const {
        show,
        title,
        text,
        children,
        onClose,
        showAccept = false,
        onAccept,
        acceptName,
        cancelName,
    } = props
    // 	handleAccept?: any,
    // handleDecline?: any
    const containerTransition = useRef(null)

    const dialogContent = {
        acceptName: 'Aceptar'
    }

    const closeOnEscapeKeyDown = (e: any) => {
        if ((e.charCode || e.keyCode) === 27) {
            onClose();
        }
    };

    const buttonStyles = {
        marginTop: 0
    }

    const acceptControlStyles = {
		backgroundColor: '#FFFFFF',
		border: '2px solid #F5F6F7',
		boxShadow:' 0px 2px 5px rgba(38, 51, 77, 0.03)',
		borderRadius: 30,
		color: '#B2B5C9',
		marginRight: 10,
        marginTop: 0
	}

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown)
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown)
        }
    });

    return (
        <CSSTransition
            nodeRef={containerTransition}
            in={show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div
                className='app_modal' 
                onClick={onClose} 
                ref={containerTransition}>
                <div className='app_modal__content' onClick={e => e.stopPropagation()}>
                    <div className='app_modal__header'>
                        <h4 className='app_modal__title'>
                            {title}
                        </h4>
                    </div>
                    <div className='app_modal__body'>

                        {text && (
                            <p>{text}</p>
                        )}

                        {children}

                    </div>
                    <div className='app_modal__footer'>
                        {showAccept &&
                            <AppButton
                                text={acceptName}
                                customStyles={acceptControlStyles}
                                callback={onAccept}
                            />
                        }
                        <AppButton
                            text={cancelName ? cancelName : dialogContent.acceptName}
                            customStyles={buttonStyles}
                            callback={onClose}
                        />
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default AppModal;
