import React from 'react'
// Components
import VacancyButton from './../VacancyButton'
// Style
import style from './style.module.scss'

const index = () => {

	return (
		<div className={style.vacancy_list}>
			<VacancyButton titleVanacy="Cajero sucursal bancaria" callback={() => { window.location.href = '#/vacancy-details' }} />
			<VacancyButton titleVanacy="Cajero sucursal bancaria" callback={() => { window.location.href = '#/vacancy-details' }} />
			<VacancyButton titleVanacy="Cajero sucursal bancaria" callback={() => { window.location.href = '#/vacancy-details' }} />
			<VacancyButton titleVanacy="Cajero sucursal bancaria" callback={() => { window.location.href = '#/vacancy-details' }} />
		</div>
	);
};

export default index;