import React from 'react'
// Components
import AppButton from '@app/components/AppButton'
import AppUploadFile from '@app/components/AppUploadFile'
// Style
import style from './style.module.scss'
// Assets
import downloadIcon from '@app/assets/donwload-icon.png'
const index = () => {
	return (
		<div className={style.upload_vacancy}>
			<h3 className={style.upload_vacancy__title}>
				1. Publicación por puestos
			</h3 >
			<p className={style.upload_vacancy__paragraph}>
				Esta información servirá como base para las publicaciones automáticas
			</p>
			<div className={style.upload_vacancy__download_buttons}>
				<AppButton
					text="Descarga plantilla"
					secondary
					icon={downloadIcon}
				/>
				<AppButton text="Cargar puesto" />
			</div>
			<p className={style.upload_vacancy__instructions}>
				Selecciona el archivo con el puesto a configurar
			</p>

			<div className={style.upload_vacancy__drag_and_drop}>
				<AppUploadFile status='loaded' />
			</div>
		</div>
	);
};

export default index;