import React from 'react'
// Components
import AppButton from '@app/components/AppButton'
import AppUploadFile from '@app/components/AppUploadFile'
// Style
import style from './style.module.scss'
// Assets
import downloadIcon from '@app/assets/donwload-icon.png'
const index = () => {
	return (
		<div className={style.upload_vacancy}>
			<h3 className={style.upload_vacancy__title}>
				Nuevo puesto
			</h3>
			<div className={style.upload_vacancy__download_buttons}>
				<AppButton
					text="Descarga plantilla"
					secondary
					icon={downloadIcon}
				/>
				<AppButton text="Cargar puesto" />
			</div>
			<p className={style.upload_vacancy__instructions}>
				Selecciona el archivo con el puesto a configurar
			</p>
			<div className={style.upload_vacancy__drag_and_drop}>
				<AppUploadFile status='waiting' />
			</div>
		</div>
	);
};

export default index;