import { Navigate, useLocation } from 'react-router-dom';
import { useZeusAuth } from '@app/context/AuthZeusConext/AuthZeusContext';

export const RequireAuthProvider = (props: any) => {

    const { component } = props

    const location = useLocation()
    const { user } = useZeusAuth()
  
    if (!user ) return <Navigate to="/login" state={{path: location.pathname}} />

    return component
}