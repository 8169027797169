import { FC } from 'react';
import { IAppTagButtonProps } from './inetrface';
import './style.scss';

// Interface

// Components
const AppTagButton:FC<IAppTagButtonProps> = (props) => {
	const {
		text,
		secondary,
		action
	} = props

	const handleClick = () => {
		if(action) action()
	}
	return (
		<div 
		className={`
			tag_button 
			${secondary ? 'tag_button__secondary': ''}
			`}
		onClick={handleClick}
		>
			{text}
		</div>
	);
};

export default AppTagButton;