const useBuilderProviderTree = (providers: any) => {

  const buildTree = (layers: any): any => {
    if (layers.length === 1) {
      return layers[0];
    }
    const Layer = layers.shift();
    const Nested = layers.shift();
    return buildTree([
      ({ children }: any) => (
        <Layer>
          <Nested>
            {children}
          </Nested>
        </Layer>
      ),
      ...layers,
    ])
  }
  return buildTree(providers)
}

export default useBuilderProviderTree