import React from 'react'
// Components
import AppNoResults from '@app/components/AppNoResults'
import AppPageTitle from '@app/components/AppPageTitle'
import UploadVacancy from './Components/UploadVacancy'
// Style
import style from './style.module.scss'
const HomeView = () => {
	return (
		<div className={style.home_view}>
			<div className={style.home_view__title}>
				<AppPageTitle
					title="Puestos por configurar"
					secondary
				/>
			</div>
			<div className={style.home_view__no_items}>
				<AppNoResults />
			</div>
			<div className={style.home_view__new_vacancy}>
				<UploadVacancy />
			</div>
		</div>
	);
};

export default HomeView;