import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from './modules/app.router';
import useBuilderProviderTree from '@app/hooks/useBuildProviderTree';
import { AuthZeusProvider } from '@app/context/AuthZeusConext/AuthZeusContext';

// Style
import '@app/theme/Global.scss';

function App () {

  const Providers = useBuilderProviderTree([
    AuthZeusProvider,
  ])

  return (
    <Providers>
      <RouterProvider router={appRouter} />
    </Providers>
  );
}

export default App;