import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
// Style
import style from './style.module.scss'
// Assets
import uploadIcon from '@app/assets/upload-icon.png'
import copyIcon from '@app/assets/copy-icon.png'
interface IAppUploadFile {
	status?: string
}
const AppUploadFile: FC<IAppUploadFile> = (props) => {
	const navigate = useNavigate()
	const redirect = (url: string) => {
		navigate(url)
		navigate(0)
	}
	const {
		status,
	} = props
	return (
		<div className={style.app_upload_file} onClick={() => redirect('/vancys-to-be-configure')}>
			{
				status === 'waiting' &&
				<div className={style.app_upload_file__upload}>
					<img src={uploadIcon} alt='Ícono de subida' /> Arrastra tu archivo o cárgalo
				</div>
			}
			{
				status === 'loaded' &&
				<div className={style.app_upload_file__loaded}>
					<div className={style.file_name}>
						<div className={style.file_name__icon}>
							<img src={copyIcon} alt='Ícono copiar' />
						</div>
						<div className={style.file_name__text}>
							Concentrado de matriz de publicacion.xlxs
						</div>
					</div>
					<div className={style.file_upload}>
						<img src={uploadIcon} alt='Ícono de subida' />
						<div className={style.file_upload__text}>
							Volver a subir
						</div>
					</div>
				</div>
			}
		</div>
	);
};

export default AppUploadFile;