import { FC } from 'react';
import { LocalStorage } from '@app/utils';
// Style
import styles from './style.module.scss';
// Assets
import reclutaliaLogo from '@app/assets/reclutalia-logo.png'
import avatarDummy from '@app/assets/avatar-default.png'
import bellIcon from '@app/assets/bell-icon.png'
import { IAppHeaderProps } from './interface';

const AppHeader: FC<IAppHeaderProps> = (props) => {
	const {
		userName = ''
	} = props

	const localStorage = new LocalStorage()

	// Temp logout
	const logout = () => {
		localStorage.clear()
		window.location.reload();
	}

	return (
		<div className={styles.app_header}>
			{/* -------------- Reclutalia logo -------------- */}
			<img className={styles.app_header__logo} src={reclutaliaLogo} alt="Logo de reclutalia" />

			<div className={styles.app_header__user_info}>
				{/* -------------- Notificaciones -------------- */}
				<div className={styles.app_header_notifications}>
					<img src={bellIcon} alt="ícono de notificaciones" />
				</div>

				{/* -------------- Avatar -------------- */}
				<div className={styles.app_header_avatar}>
					<div className={styles.avatar_name}>
						Hola {userName}
					</div>
					<div className={styles.avatar_image} onClick={logout}>
						<img src={avatarDummy} alt='Imagen de perfil' />
					</div>
				</div>

			</div>

		</div>
	);
};

export default AppHeader;