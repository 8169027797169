import { API_RECLUTALIA, RECLUTALIA } from '@app/requests/api';
import { AxiosRequestConfig } from 'axios';

export interface ResponseAPI {
    userId: number;
    id: number;
    title: string;
    body: string;
}

export const getOwnerService = () => {

    const getOwnerByEmployeeNumber = async (provider: string, employeeNumber: string) => {

        try {

            const config: AxiosRequestConfig = {
                method: "GET",
                url: RECLUTALIA.AUTH.GET_OWNER(provider, employeeNumber),
                headers: { 'Content-Type': 'application/json'}
            }

            const response = await (await API_RECLUTALIA(config)).data.data
            
            return response
            
        }catch(e) {
            console.error(e)
            //throw new Error(handleCommonErrors(e as AxiosError));
        }
    }

    return  {

        getOwnerByEmployeeNumber
    }
}

// function handleCommonErrors (args: AxiosError<unknown, any>): string | undefined {
//     throw new Error('Function not implemented.');
// }