import { IStorage } from "./IStorage";
import { CryptoAesService } from "./CryptoAesService";

export class StorageBase implements IStorage {

  // Crypto services to encrypt the items value
  private $cryptoAesService: CryptoAesService;

  constructor() {
    this.$cryptoAesService = new CryptoAesService();
    return this;
  }

  /**
   * Clear all the items saved in storage
   */
  public clear(): void {
    throw new Error('Method not implemented.');
  }

  /**
   * Sets a new item in the storage
   * @param _key the key of the item
   * @param _value the value of the item to saved
   */
  public setItem(_key: string, _value: string): IStorage {
    return this;
  }

  /**
   * Gets an item saved in storage
   * @param _key the key of the item to get
   */
  public getItem(_key: string): string {
    throw new Error('Method not implemented.');
  }

  /**
   * Removes an item saved in the storage
   * @param _key the key of the item to removed
   */
  public removeItem(_key: string): void {
    throw new Error('Method not implemented.');
  }

  /**
   * Gets value for CryptoAesService
   */
  protected get cryptoAesService(): CryptoAesService {
    return this.$cryptoAesService;
  }

  /**
   * Sets value for  CryptoAesService
   */
  protected set cryptoAesService(cryptoAesService: CryptoAesService) {
    this.$cryptoAesService = cryptoAesService;
  }

  /**
   * Gets an item value parsed to JSON
   * @param value the value of the item
   */
  public parseStringToJSON(value: string): JSON {
    return JSON.parse(value);
  }

  /**
   * Parse a JSON to string
   * @param value the value to parse
   */
  public parseJSONToString(value: any): string {
    return JSON.stringify(value);
  }
}
