import { StorageBase } from "./StorageBase";
import { IStorage } from "./IStorage";

class LocalStorageService extends StorageBase {

  constructor() {
    super();
    return this;
  }

  /**
   * Clear all the items saved in storage
   */
  public clear(): void {
    localStorage.clear();
  }

  /**
   * Sets a new item in the storage
   * @param key the key of the item
   * @param value the value of the item to saved
   */
  public setItem(key: string, value: string): IStorage {
    const encrypted = this.cryptoAesService.encryptUsingAES256(value);
    localStorage.setItem(key, encrypted);
    return this;
  }

  /**
   * Gets an item saved in storage
   * @param key the key of the item to get
   */
  public getItem(key: string): string {
    const itemValueEncrypted = localStorage.getItem(key);
    if (itemValueEncrypted) {
      return this.cryptoAesService.decryptUsingAES256(itemValueEncrypted);
    } else {
      return '';
    }
  }

  /**
   * Removes an item saved in the storage
   * @param key the key of the item to removed
   */
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

}

export default LocalStorageService;