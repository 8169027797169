
export const temporalVars = {
    REACT_APP_ASSETS_BUCKET: "qa.reclutalia.assets",
    REACT_APP_BASE_API_GATEWAY: "https://api.reclutalia-qa.com",
    REACT_APP_ZEUS_GS: "https://api.mobile.zeusgs.com.mx/login/validate",
    REACT_APP_ENV: "qa",
    REACT_APP_ENCRYPT_LOGIN: "https://s0blefoi3a.execute-api.us-east-1.amazonaws.com/dev/encrypt",
    REACT_APP_DECRYPT_LOGIN: "https://s0blefoi3a.execute-api.us-east-1.amazonaws.com/dev/decrypt",
} 

export const environment = temporalVars // process.env

// process.env.NODE_ENV
