import { FC, useEffect, useRef } from 'react';
import { CSSTransition } from "react-transition-group";
import style from './style.module.scss'
import closeIcon from '@app/assets/icon-close-white-rounded.svg'
import clockIcon from '@app/assets/icon-clock-white.svg'
import rocketIcon from '@app/assets/icon-rocket-white.svg'
import { IAppAlertProps } from './interface';

const AppAlert:FC<IAppAlertProps> = (props) => {

	const {
        show,
        children,
		title,
		severity,
		action
	} = props

    const alertTransitionRef = useRef(null)

	const handleClick = () => {
		if(action) action()
	}

    const typeClass = severity ? style[`is_${severity}`] : style.is_default

    const classContainer = style.app_alert + ' ' + typeClass
    
    const iconAlert = {
        'danger': closeIcon,
        'info': clockIcon,
        'success': rocketIcon
    }

    useEffect(() => {

        
    },[])

	return (
         <CSSTransition
            nodeRef={alertTransitionRef}
            in={show}
            unmountOnExit
            timeout={{ enter: 0, exit: 0 }}
        >
            <div
                ref={alertTransitionRef}
                className={classContainer}
                onClick={handleClick}
            >
                <div className={style.app_alert__content}>
                    {severity &&
                        <div className={style.app_alert__icon}>
                            <img 
                                src={iconAlert[severity]}
                                alt={severity} 
                            />
                        </div>
                    }
                    <div className={style.app_alert__text}>

                        {title &&
                            <h4 className={style.app_alert__title}>{title}</h4>
                        }

                        {children}

                    </div>
                </div>
            </div>
        </CSSTransition>
		
	);
};

export default AppAlert;