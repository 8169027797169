import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { logintZeusSGService } from '../../Services/auth-user.service';
import { encryptService, decryptService } from '../../Services/crypt.service';
import AppSpinner from '@app/components/AppSpinner';

import style from './style.module.scss';

import brandImage from '@app/assets/grupo-salinas.png';
import { getOwnerService } from '../../Services/owner.service';
import { useZeusAuth } from '@app/context/AuthZeusConext/AuthZeusContext';

const LoginForm = ({ handleForm }: any) => {

    const [loadingForm, setLoadingForm] = useState(false)

    const auth = useZeusAuth()

    const { getOwnerByEmployeeNumber } = getOwnerService()

    const navigate = useNavigate()
    const location = useLocation()

    const labelNameRef = useRef(null)
    const labelPassRef = useRef(null)
    
    const redirectPath = location.state?.path || '/'

    const yupValidation = Yup.object().shape({
        username: Yup.string()
            .required()
            .min(1),
        password: Yup.string().required(),
    })
    const formOptions = { resolver: yupResolver(yupValidation) }
    const { register, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState

    const contentForm = {
        title: 'Iniciar sesión',
        fields: {
            username: {
                label: 'Usuario de llave maestra',
                type: 'text',
                name: 'username',
                required: true,
                errorMaessage: 'Ingresa el usuario'
            },
            password: {
                label: 'Contraseña',
                type: 'password',
                name: 'password',
                required: true,
                errorMaessage: 'Ingresa la contraseña'
            }
        },
        submitName: 'Ingresar',
        errorsCode: {
            ownerNoExist: 'loginZeus',
            ownerNoValid: 'noPermissions'
        }
    }

    const handleFocus = (targetRef: any) => {
        targetRef.style.color = '#7F65F6'
    }

    const handleBlur = (targetRef: any) => {
        targetRef.style.color = 'inherit'
    }

    const onSubmit = async (data: any) => {

        try {

            //const validRoleName = 'ADMINISTRADOR LOCAL'

            // const hasValidRoleName = (inputName: string) => {
            //     const roleName = inputName ? inputName.toLowerCase().replaceAll(' ', '') : undefined
            //     const resultCompare = roleName === validRoleName.toLowerCase().replaceAll(' ', '')
            //     return roleName ? resultCompare : false
            // }
            
            data.external_user = false
            data.employee_number = data.username

            setLoadingForm(true)

            const encryptResponse = await encryptService(data)
            const validateZeusGSResponse = await logintZeusSGService(encryptResponse.body.data)
            const decryptResponse = await decryptService(validateZeusGSResponse.data)

            if(!decryptResponse || decryptResponse?.body?.success === false || decryptResponse?.body?.data?.code === 500 || !decryptResponse?.body?.data?.user ) {
                
                setLoadingForm(false)
                handleForm({resolve: false, errorCode: contentForm.errorsCode.ownerNoExist})
            } else {

                const ownersResponse = await getOwnerByEmployeeNumber('OKTA', data.employee_number)

                const ownerNoExist = !ownersResponse || 
                    ownersResponse?.data === null || 
                    ownersResponse?.data === ''

                if (ownerNoExist) {
                    setLoadingForm(false)
                    handleForm({ resolve: false, errorCode: contentForm.errorsCode.ownerNoExist })
                    throw new Error('owner no exist')
                }

                const validOwner = ownersResponse?.role?.id === 3

                if(!validOwner) {
                    setLoadingForm(false)
                    handleForm({ resolve: false, errorCode: contentForm.errorsCode.ownerNoValid})
                    throw new Error('no valid owner')
                }

                const userData = {
                    fullName: `${ownersResponse?.firstName} ${ownersResponse?.lastName}`
                }
                
                auth.login(userData.fullName)
                setLoadingForm(false)
                handleForm({resolve: true})
                
                return navigate(redirectPath, { replace: true })
            }
            
        } catch(e) {
            console.log(e)
            setLoadingForm(false)
            //handleForm({ resolve: false, errorCode: contentForm.errorsCode.ownerNoExist })
        }
    }

    useEffect(() => {
       if(auth.user) navigate('/', { replace: true })
    })

	return (
		<>
            <div className={style.login_form}>
                <div className={style.login_form__heading}>
                    <div className={style.login_form__heading_brand}>
                        <img
                            src={brandImage}
                            height={70}
                            width={100}
                            alt="Grupo Salinas" />
                    </div>
                </div>
                <div className={style.login_form__body}>
                    <h2 className={style.login_form__title}>
                        {contentForm.title}
                    </h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div
                            className={style.login_form__control}
                            onFocus={() => { handleFocus(labelNameRef.current) }}
                            onBlur={() => { handleBlur(labelNameRef.current) }}
                        >
                            <label className={style.login_form__control_label} ref={labelNameRef}>
                                {contentForm.fields.username.label}
                            </label>
                            <input
                                className={`${style.login_form__control_field} ${errors.username ? 'is_invalid' : ''}`}
                                type={contentForm.fields.username.type}
                                {...register(contentForm.fields.username.name)}
                            />
                            {errors.username && (
                                <p className={style.login_form__control_error}>
                                    {contentForm.fields.username.errorMaessage}
                                </p>
                            )}
                        </div>
                        <div
                            className={style.login_form__control}
                            onFocus={() => { handleFocus(labelPassRef.current) }}
                            onBlur={() => { handleBlur(labelPassRef.current) }}
                        >
                            <label className={style.login_form__control_label} ref={labelPassRef}>
                                {contentForm.fields.password.label}
                            </label>
                            <input
                                className={style.login_form__control_field}
                                type={contentForm.fields.password.type}
                                {...register(contentForm.fields.password.name)}
                            />
                            {errors.password && (
                                <p className={style.login_form__control_error}>
                                    {contentForm.fields.password.errorMaessage}
                                </p>
                            )}
                        </div>
                        <div className={style.login_form_control}>
                            <button
                                disabled={loadingForm}
                                className={style.login_form__submit}
                                type="submit">
                                {loadingForm ?
                                    <AppSpinner /> : contentForm.submitName
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
	);
};

export default LoginForm;