// React
import { createHashRouter } from 'react-router-dom';
import { RequireAuthProvider } from '@app/providers/ProtectedRoute';

// Layouts
import LoginLayout from './Login/LoginLayout';
import HomeLayout from './Home/HomeLayout';

// Routes
import { HomeRoutes } from './Home/Modules/Home.router';

export const appRouter = createHashRouter([
  {
    path: '/',
    element: <RequireAuthProvider component={<HomeLayout />} />,
    children: HomeRoutes
  },
  {
    path: '/login',
    element: <LoginLayout />
  }
]);