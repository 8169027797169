import { useState } from 'react';
import LoginForm from './Components/LoginForm';
import AppModal from '@app/components/AppModal';
import style from './style.module.scss'

const LoginLayout = () => {

    const warningInitialState = { title: '', text: '' }

    const [showDialog, setShowDialog] = useState(false)
    const [warning, setWarning] = useState(warningInitialState)

    const ownerNoValid = {
        title: '¡No tienes permisos para acceder a este sitio!',
        text: 'Al parecer no cuentas con el rol para acceder a este sitio. Si crees que es un error, contacta con Botón Rojo.'
    }

    const ownerNoExist = {
        title: '¡Ha ocurrido algo!',
        text: 'Tu usuario o contraseña son incorrectos por favor intenta de nuevo.'
    }

    const initErrorMessage = (error: string): void => {

        if(error === 'noPermissions') {
            setWarning(ownerNoValid)
        }else{
            setWarning(ownerNoExist)
        }
        setShowDialog(true)
    }

    const handleForm = (control: any) => {
        const { resolve, errorCode } = control
        if(!resolve) return initErrorMessage(errorCode)
    }

	return (
		<>
            <section className={style.login_view}>
                <div className={style.login_view__container}>
                    <LoginForm handleForm={handleForm} />
                </div>
            </section>

            <AppModal
                title={warning.title}
                text={warning.text}
                onClose={() => setShowDialog(false)}
                show={showDialog}
            />
        </>
	);
};

export default LoginLayout;