import { FC, ReactElement, ReactFragment, ReactNode } from 'react';
// Style
import style from './style.module.scss'
import AppTooltip from '../AppTooltip';

type tooltipType = ReactElement | ReactNode | ReactFragment

interface IAppPageTitleProps {
	title: string,
	secondary?: boolean,
	tooltip?: tooltipType,
}
const AppPageTitle: FC<IAppPageTitleProps> = (props) => {
	const {
		title,
		secondary = false,
		tooltip
	} = props

	const markupTooltip = (content: tooltipType, selector: string) => {

		return (
			<div className={selector}>
				<AppTooltip>
					{content}
				</AppTooltip>
			</div>
		)
	}

	return (
		<div className={`
		${style.app_page_title}
		${secondary ? style.app_page_title__secondary : ''}
		`}>
			
			{ title } 
			
			{ tooltip && markupTooltip(tooltip, style.app_page_title__tooltip) }

		</div>
	);
};

export default AppPageTitle;